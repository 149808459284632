/* You can add global styles to this file, and also import other style files */

html, body {  --tw-bg-opacity: 1 !important; background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;}
body { margin: 0; font-size: 14px}

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@layer base {
  html {
    font-family: "Manrope", sans-serif;
  }
}

.flex:focus-within.date-range.custom-outline {
  outline: 2px solid #3b82f6;
  background-color: white;
}

.date-range input[type="date"]:focus {
  outline: none;
  z-index: 2;
}
